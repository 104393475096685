<template>
  <div class="p-grid p-jc-center">
    <div class="p-col-10">
      <Fieldset legend="วิดีโอสอนการใช้งานระบบ">
        <div style="margin-bottom: 5px">
          <div class="p-d-flex p-jc-between">
            <Button
              label="ย้อนกลับ"
              icon="pi pi-arrow-left"
              class="p-button-sm p-button-warning"
              @click="() => $router.back(-1)"
            />
          </div>
        </div>

        <Divider align="left" type="dashed">
          <div class="p-d-inline-flex p-ai-center">
            <i class="pi pi-video p-mr-2"></i>
            <b>วิดีโอสอนการอ่านกราฟ</b>
          </div>
        </Divider>

        <div style="padding-left: 25px">
          <p v-for="(vdo, index) in graphs" :key="index">
            <a style="cursor: pointer" @click="() => openModal(vdo)">
              {{ index + 1 }}) {{ vdo.topic }} [{{ vdo.time }}]
              <i class="pi pi-video"></i>
            </a>
          </p>
        </div>

        <Divider align="left" type="dashed">
          <div class="p-d-inline-flex p-ai-center">
            <i class="pi pi-video p-mr-2"></i>
            <b
              >วิดีโอสอนในการงานระบบวิเคราะห์ภาวะสุขภาพกับการทำงานของผู้สูงอายุเพื่อหาโอกสาสส่งเสริมอาชีพในระดับท้องถิ่น</b
            >
          </div>
        </Divider>
        <div style="padding-left: 25px">
          <p v-for="(vdo, index) in analytics" :key="index">
            <a style="cursor: pointer" @click="() => openModal(vdo)">
              {{ index + 1 }}) {{ vdo.topic }} [{{ vdo.time }}]
              <i class="pi pi-video"></i>
            </a>
          </p>
        </div>
      </Fieldset>
    </div>

    <Dialog
      :header="video.topic"
      :visible="isModal"
      :contentStyle="{ overflow: 'visible' }"
      :closable="false"
      style="width: 80%"
    >
      <YouTube
        :src="youtube + video.youtubeId"
        @ready="onReady"
        ref="youtube"
        :width="'100%'"
      />
      <template #footer>
        <Button
          label="ปิด"
          icon="pi pi-times-circle"
          @click="() => (isModal = false)"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
/*  https://www.npmjs.com/package/vue3-youtube  */
import YouTube from 'vue3-youtube'

export default {
  layout: 'Public',
  data() {
    return {
      isModal: false,
      youtube: 'https://www.youtube.com/watch?v=',
      video: { topic: null, link: null },
      graphs: [
        {
          topic: 'เป้าหมายของการทำ Data Visualization',
          youtubeId: 'SbVPn3B08IU',
          time: '5:20'
        },
        {
          topic: 'ประเภทของการนำเสนอข้อมูล (Data Visualization)',
          youtubeId: '0ZLo-8w8iVg',
          time: '13:14'
        },
        {
          topic: 'การเลือกคุณลักษณะของตัวแปร (Feature Selection)',
          youtubeId: 'k83GQvcF0_s',
          time: '2:20'
        }
      ],
      analytics: [
        {
          topic: 'การล็อกอินเข้าใช้งานระบบ',
          youtubeId: 'gZ7FSx245XQ',
          time: '1:01'
        },
        {
          topic: 'การดูผลวิเคราะห์ผ่าน Dashboard',
          youtubeId: 'XLnuxlsG6JM',
          time: '1:33'
        },
        {
          topic: 'แบบจำลองการประเมินความสามารถในการทำงาน',
          youtubeId: 'L1_Iak5GCLg',
          time: '1:03'
        },
        {
          topic: 'แบบจำลองการประเมินความสามารถในการประกอบอาชีพ',
          youtubeId: 'zR9gzonI9gs',
          time: '0:57'
        },
        {
          topic: 'จัดการข้อมูลผู้สูงอายุ',
          youtubeId: 'DvCRhVx7N_0',
          time: '2:25'
        },
        {
          topic: 'นำเข้าข้อมูลผู้สูงอายุ',
          youtubeId: '0Zn3eXkqabo',
          time: '1:40'
        },
        {
          topic: 'สถานะการสำรวจข้อมูล',
          youtubeId: 'h_Swkw94Xg8',
          time: '1:21'
        },
        {
          topic: 'นำเข้าข้อมูลสุขภาพ (Health 43 แฟ้ม)',
          youtubeId: 'fZAFUPMkuck',
          time: '1:09'
        },
        {
          topic: ' จัดการพื้นที่',
          youtubeId: 'wkiSHWrmIZs',
          time: '1:51'
        },
        {
          topic: ' จัดการผู้ใช้งาน',
          youtubeId: 'QiJdRZ3arTY',
          time: '2:04'
        }
      ]
    }
  },
  methods: {
    openModal(vdo) {
      this.isModal = true
      this.video = vdo
    },
    onReady() {
      this.$refs.youtube.playVideo()
    }
  },
  components: {
    YouTube
  }
}
</script>